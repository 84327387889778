<template>
    <div class="relative">
        <div class="absolute left-0 bottom-0 pointer-events-none overflow-hidden">
            <svg
                class="md:h-[540px] md:w-auto h-auto w-full"
                viewBox="0 0 477 540"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M-109.448 481.094C-109.448 419.291 -60.2362 369.573 0.000117533 369.573C60.2364 369.573 109.448 419.291 109.448 481.094C109.448 542.898 60.2364 592.616 0.000117533 592.616C-60.2362 592.616 -109.448 542.898 -109.448 481.094Z"
                    stroke="#FFD61E"
                    stroke-width="18" />
                <path
                    d="M-205.477 481.094C-205.477 366.287 -113.292 273.564 0.000166287 273.564C113.292 273.564 205.477 366.287 205.477 481.094C205.477 595.902 113.292 688.626 0.000166287 688.626C-113.292 688.626 -205.477 595.902 -205.477 481.094Z"
                    stroke="#FFD61E"
                    stroke-opacity="0.74"
                    stroke-width="30" />
                <path
                    d="M-322.682 481.094C-322.682 300.305 -177.917 154.286 -0.000162351 154.286C177.917 154.286 322.682 300.305 322.682 481.094C322.682 661.884 177.917 807.903 -0.000162351 807.903C-177.917 807.903 -322.682 661.884 -322.682 481.094Z"
                    stroke="#FFD61E"
                    stroke-opacity="0.5"
                    stroke-width="36" />
                <path
                    d="M-437.372 482.142C-437.372 237.334 -241.146 39.6196 1.33514e-05 39.6196C241.146 39.6196 437.372 237.334 437.372 482.142C437.372 726.952 241.146 924.666 1.33514e-05 924.666C-241.146 924.666 -437.372 726.952 -437.372 482.142Z"
                    stroke="#FFD61E"
                    stroke-opacity="0.25"
                    stroke-width="54" />
            </svg>
        </div>
    </div>
</template>

<script setup>

</script>
